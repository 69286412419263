body {
  background-color: #282c34;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
text {
  fill: white !important;
}

.main-component {
  position: relative;
  width: 100%;
  /* margin-right: 22px; */
  margin-left: 8px;
}
.text-design {
  position: absolute;
  top: -12px;
  left: 10px;
  padding: 2px 5px;
  background-color: #282c34;
  color: white;
  font-size: 14px;
}
.m-component {
  position: absolute;
  top: 45px;
  z-index: 50;
  width: 100%;
}
.m-hidden {
  position: absolute;
  top: 45px;
  z-index: 50;
  width: 100%;
}

.child-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: #353840;
  border-radius: 5px;
  gap: 15px;
  padding: 20px;
}

.hover-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  background-color: #353840;
  border-radius: 5px;
  padding: 20px;
  gap: 15px;
}

.month-box {
  padding: 10px 20px;
  font-size: x-large;
  cursor: pointer;
  font-weight: 600;
  text-align: center;
  border-radius: 10px;
  background-color: #41444b;
  transition: all 0.35s;
  cursor: pointer;
  color: #fff;
}
.month-box:hover {
  border-radius: 10px;
  background-color: #4795ea;
}

.day-picker-container {
  border-radius: 10px;
}
@media (max-width: 768px) {
  .month-box {
    padding: 10px 20px;
    font-size: large;
    cursor: pointer;
    font-weight: 600;
    text-align: center;
    border-radius: 10px;
    background-color: #41444b;
    transition: all 0.35s;
    cursor: pointer;
    color: #fff;
  }
  .month-box:hover {
    border-radius: 10px;
    background-color: #4795ea;
  }
}

.hour-box {
  padding: 10px 20px;
  font-size: x-large;
  cursor: pointer;
  font-weight: 600;
  text-align: center;
  border-radius: 10px;
  background-color: #41444b;
  transition: all 0.35s;
  cursor: pointer;
  color: #fff;
}
.hour-box:hover {
  border-radius: 10px;
  background-color: #4795ea;
}

@media (max-width: 768px) {
  .hour-box {
    padding: 5px 5px;
    font-size: medium;
    cursor: pointer;
    font-weight: 600;
    text-align: center;
    border-radius: 10px;
    background-color: #41444b;
    transition: all 0.35s;
    cursor: pointer;
    color: #fff;
  }
  .hour-box:hover {
    border-radius: 10px;
    background-color: #4795ea;
  }
  .hover-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

.input-tag {
  color: white;
  width: 99.5%;
  height: 18px;
  padding: 10px 0px 10px 0px;
  background-color: #282c34;
  border: 1px solid white;
  border-radius: 5px;
}
.input-tag:hover {
  border: 1px solid #2976bc;
}
.p-text {
  padding-left: 10px;
}
.day-picker {
  background-color: #353840;
  padding: 20px;
  border-radius: 5px;
}
.day-picker > div {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 15px;
}
.day-picker h3 {
  margin: 0;
  padding-bottom: 25px;
  font-size: 24px;
  line-height: 1;
  color: #fff;
}
.date-picker-bg {
  background: #41444b;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  color: #fff;
  aspect-ratio: 1 / 1;
  transition: all 0.35s;
  cursor: pointer;
}
.date-picker-bg:hover {
  background-color: #4795ea;
  color: #ffffff;
}

@media (min-width: 900px) {
  @media (max-width: 1700px) {
    .hover-container {
      padding: 10px;
      gap: 10px;
      height: calc(100vh - 375px) !important;
        overflow-y: auto !important;
    }
    .hour-box {
      padding: 1vw;
      font-size: 1.5vw;
      cursor: pointer;
      font-weight: 600;
      text-align: center;
      border-radius: 8px;
      background-color: #41444b;
      transition: all 0.35s;
      cursor: pointer;
      color: #fff;
    }
    .hour-box:hover {
      border-radius: 8px;
      background-color: #4795ea;
    }
    .day-picker {
      background-color: #353840;
      padding: 10px;
      border-radius: 5px;
    }
    .day-picker > div {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      max-height: calc(100vh - 320px);
        overflow-y: auto !important;
      gap: 10px;
    }
    .day-picker h3 {
      margin: 0;
      padding-bottom: 25px;
      font-size: 1.5vw;
      line-height: 0.7;
      color: #fff;
    }
    .date-picker-bg {
      background: #41444b;
      border: none;
      border-radius: 5px;
      font-size: 21px;
      color: #fff;
      aspect-ratio: 1 / 1;
      transition: all 0.35s;
      cursor: pointer;
    }
    .date-picker-bg:hover {
      background-color: #4795ea;
      color: #ffffff;
    }
  }
  @media (max-width: 1250px) {
    .hover-container {
      padding: 6px;
      gap: 6px;
    }
    .hour-box {
      padding: 0.8vw;
      font-size: 1vw;
      cursor: pointer;
      font-weight: 600;
      text-align: center;
      border-radius: 6px;
      background-color: #41444b;
      transition: all 0.35s;
      cursor: pointer;
      color: #fff;
    }
    .hour-box:hover {
      border-radius: 6px;
      background-color: #4795ea;
    }
    .day-picker {
      background-color: #353840;
      padding: 5px;
      border-radius: 5px;
    }
    .day-picker > div {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      gap: 5px;
    }
    .day-picker h3 {
      margin: 0;
      padding-bottom: 25px;
      font-size: 18px;
      line-height: 0.7;
      color: #fff;
    }
    .date-picker-bg {
      background: #41444b;
      border: none;
      border-radius: 5px;
      font-size: 1vw;
      color: #fff;
      aspect-ratio: 1 / 1;
      transition: all 0.35s;
      cursor: pointer;
    }
    .date-picker-bg:hover {
      background-color: #4795ea;
      color: #ffffff;
    }
  }
}

.css-hn784z, .css-1abc02a{
  height: 85% !important
}

.css-1m9128y {
  max-width: 80vw !important;
  min-width: 50vw !important;
  height: 5vh;
  visibility: hidden;
}
@media (max-width: 899px) {
  .home-container {
    height: auto !important;
  }
  .home-grid {
    height: auto !important;
  }
  .home-grid-item {
    height: auto !important;
    max-height: 100% !important;
    max-width: 50% !important;
  }
  .home-grid-item > div {
    max-height: unset !important;
  }
  .css-1m9128y {
    max-width: 85vw !important;
    min-width: 50vw !important;
    max-height: 10vh;
  }
  .css-1m9128y {
    max-width: 80vw !important;
    min-width: 50vw !important;
    
  }
}
.css-1m9128y {
  margin-top: 0 !important;
}
@media (max-width: 767px) {
  .home-grid-item {
    max-width: 100% !important;
  }
  .css-1m9128y {
    min-width: 95vw !important;
  }
}

@media (max-width: 768px) {
  .day-picker {
    background-color: #353840;
    padding: 10px;
    border-radius: 5px;
  }
  .day-picker > div {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
  }
  .day-picker h3 {
    margin: 0;
    padding-bottom: 25px;
    font-size: 18px;
    line-height: 0.7;
    color: #fff;
  }
  .date-picker-bg {
    background: #41444b;
    border: none;
    border-radius: 5px;
    font-size: 12px;
    color: #fff;
    aspect-ratio: 1 / 1;
    transition: all 0.35s;
    cursor: pointer;
  }
  .date-picker-bg:hover {
    background-color: #4795ea;
    color: #ffffff;
  }
}

.flex-common-class {
  display: flex;
  flex-direction: column;
  background-color: #353840;
  gap: 15px;
  padding: 10px;
  border-radius: 5px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.button-design {
  color: #fff;
  background-color: #56595f;
  border-radius: 10px;
  font-size: 20px;
  padding: 10px 25px;
  border: 0px;
  text-align: center;
  font-weight: 900;
  cursor: pointer;
  transition: all 0.35s;
}
.button-design:hover {
  background-color: #4795ea;
  color: #ffffff;
}
.button-item {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 370px) {
  .day-picker > div {
    grid-template-columns: repeat(4, 1fr);
  }
  .day-picker h3 {
    line-height: 1;
  }
  .child-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .hover-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px) and (max-width: 1530px) {
  .day-picker > div {
    grid-template-columns: repeat(4, 1fr) !important;
  }

  .day-picker h3 {
    line-height: 1;
  }

  .child-container {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .hover-container {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@keyframes fadeThroughWhite {
  0% { opacity: 1; }
  50% { opacity: 0.1; background-color: white; }
  100% { opacity: 1; }
}

.fade-through-white {
  animation: fadeThroughWhite 0.03s ease-in-out;
}



